<template>
  <b-card>
    <b-card-header
      class="justify-content-start p-0 pb-1 mb-1 border-bottom"
    >
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="outline-secondary"
        @click="$router.back()"
      >
        <feather-icon
          class="mr-25"
          icon="ChevronLeftIcon"
        />
      </b-button>
      <h3 class="mb-0 ml-2 d-flex justify-space-between">
        <span>{{ startupName }}</span>
      </h3>
    </b-card-header>
    <b-tabs
      v-model="activeTab"
      nav-class="mb-3"
      class="h-scroll-tabs"
      pills
      @input="updateHash"
    >
      <b-tab
        class="text-left"
        href="BusinessDetails"
        title="Business Details"
        lazy
      >
        <Document />
      </b-tab>
      <b-tab
        class="text-left"
        href="Milestones"
        title="Milestones"
        lazy
      >
        <Milestones />
      </b-tab>
      <b-tab
        class="text-left"
        href="Growths"
        title="Growths"
        lazy
      >
        <Growth />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import {
  BButton, BCardHeader,
  BTab, BTabs,
} from 'bootstrap-vue'

import Milestones from '@/views/incubator-portal/startups/pages/startup-manager/Milestones.vue'
import Growth from '@/views/incubator-portal/startups/pages/startup-manager/Growth.vue'
import Document from '@/views/incubator-portal/startups/pages/startup-manager/Document.vue'
import gql from 'graphql-tag'

export default {
  components: {
    BCardHeader,
    BButton,
    Milestones,
    Growth,
    Document,
    BTab,
    BTabs,
  },
  data() {
    return {
      activeTab: 0,
      tabs: ['#BusinessDetails',
        '#Milestones',
        '#Growths'],
      startupName: '',
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.activeTab = this.tabs.findIndex(tab => tab === this.$route.hash)
    })
  },
  methods: {
    updateHash(e) {
      this.$router.replace(this.$route.path + this.tabs[e])
    },
  },
  apollo: {
    startupName: {
      query() {
        return gql`
           query startup_name_from_assoc_id{
           users_associationtable_by_pk(id: ${this.$route.params.id} ) {
             users_organizationtable {
                title
                registered_title
                }
            }
           }`
      },
      update: data => data.users_associationtable_by_pk.users_organizationtable.title || data.users_associationtable_by_pk.users_organizationtable.registered_title,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
